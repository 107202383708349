/* Default to 100vh (fallback) */
.custom-height {
  height: 100vh;
}

/* Check for support for 100dvh */
@supports (height: 100dvh) {
  .custom-height {
    height: 100dvh;
  }
  .custom-height-modals {
    height: 100dvh;
  }
}
